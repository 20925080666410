/* eslint-disable class-methods-use-this */
import UrlPattern from 'url-pattern';

import type { SubscriptionUserType } from '@ha/api/v2/getSubscriptionProductsByHandle';
import { GuarantorType } from '@ha/api/v2/setBookingGuarantor';
import { AUTH_SIGNIN_URL, AUTH_SIGNUP_URL } from '@ha/auth/common/routes';
import {
  directMessaging,
  getTenantsGlobalBlogPath,
  integrations,
  landlordPayments,
  renting,
  rentingOut,
  securePayments,
  tenantPayments,
  TopicCluster,
} from '@ha/contentful';
import { Language } from '@ha/intl';
import { buildParams, buildSearchQuery } from '@ha/search-query';

import { ConversationsFilters } from 'ha/constants/ConversationsFilters';
import { PricingUrls } from 'ha/constants/PricingPageSlug';
import { PrivateLandlordPageCountrySlugMap } from 'ha/constants/PrivateLandlordLandingPageSlug';

import { base64 } from 'ha/utils/base64';
import { encodeSearchQuery } from 'ha/utils/encodeSearchQuery';
import { formatHrefURL } from 'ha/utils/formatHrefURL';
import { isAbsoluteUrl } from 'ha/utils/isAbsoluteUrl';
import { capitalizeFirst } from 'ha/utils/string/capitalizeFirst';
import { getCityCountryUrl } from 'ha/utils/urls/getCityCountry';

import { landlordBlogPath } from 'ha/modules/Blog/constants';
import { MUL_QUERY_KEY } from 'ha/modules/MultiUnitListings/constants';
import { AvailabilityStatusType } from 'ha/pages/AvailabilityChecker/types';
import { Query as BillingSubscriptionActivateQuery } from 'ha/pages/BillingSubscriptionActivate/types';
import { BookingFlowSteps } from 'ha/pages/ContactAdvertiser/types';
import { PREV_TO_NEXT_HELP_CENTER_PATHS } from 'ha/pages/HelpCenter/constants';
import { PayMode } from 'ha/pages/Pay/types';
import { USER_COUNTRY_DEFAULT_VALUE } from 'ha/pages/PrivateLandlordLanding/constants';
import { ProfileSectionsValues } from 'ha/pages/ProfileEdit/constants';

import { socialUrls } from './constants';

const becomeAnAdvertiserLangToPath = {
  [Language.en]: '/become-an-advertiser',
  [Language.it]: '/dai-in-affitto',
  [Language.es]: '/hazte-anunciante',
  [Language.de]: '/inserent-werden',
  [Language.fr]: '/devenir-annonceur',
  [Language.nl]: '/verhuurder-worden',
};

const SupportedCountriesUrls = {
  [Language.en]: 'https://answers.housinganywhere.com/en/articles/5249847',
  [Language.nl]: 'https://answers.housinganywhere.com/nl/articles/5249847',
  [Language.fr]: 'https://answers.housinganywhere.com/fr/articles/5249847',
  [Language.de]: 'https://answers.housinganywhere.com/de/articles/5249847',
  [Language.it]: 'https://answers.housinganywhere.com/it/articles/5249847',
  [Language.pt]: 'https://answers.housinganywhere.com/pt/articles/5249847',
  [Language.es]: 'https://answers.housinganywhere.com/es/articles/5249847',
};

const rentRadarLanguanges = [
  Language.de,
  Language.nl,
  Language.es,
  Language.it,
];

const rentRadarLanguangesWithLeadFormSupported = [Language.de, Language.nl];

const krugerLangToPath = {
  [Language.en]:
    '/housinganywhere-acquires-german-classifieds-website-studenten-wg',
  [Language.de]:
    '/de/housinganywhere-uebernimmt-die-deutsche-website-fuer-kleinanzeigen-studenten-wg',
  [Language.es]:
    '/es/housinganywhere-adquiere-studenten-wg-el-sitio-web-aleman-de-anuncios-clasificados',
  [Language.it]:
    '/it/housinganywhere-acquisisce-studenten-wg-il-sito-di-annunci-immobiliari-tedesco',
  [Language.nl]:
    '/nl/housinganywhere-neemt-duitse-marktplaats-studenten-wg-over',
};

const answersLangToPath = {
  [Language.en]: 'https://answers.housinganywhere.com/en/',
  [Language.es]: 'https://answers.housinganywhere.com/es/',
  [Language.fr]: 'https://answers.housinganywhere.com/fr/',
  [Language.de]: 'https://answers.housinganywhere.com/de/',
  [Language.it]: 'https://answers.housinganywhere.com/it/',
  [Language.pt]: 'https://answers.housinganywhere.com/pt/',
  [Language.nl]: 'https://answers.housinganywhere.com/nl/',
};

const calendarSyncGuidePathByLang = {
  [Language.en]: '/calendar-sync-guide',
  [Language.es]: '/calendario-sincronizar-guia',
  [Language.fr]: '/synchronisation-calendrier-guide',
  [Language.de]: '/kalender-synchronisieren-leitfaden',
  [Language.it]: '/guida-sincronizzare-calendario',
  [Language.nl]: '/hoe-kalender-synchroniseren',
};

export const ContractTypeExplanationArticle = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/6674927-wie-wird-die-miete-fur-die-verschiedenen-vertragsarten-berechnet',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/6674927-how-is-the-rent-calculated-for-different-contract-types',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/6674927-hoe-wordt-de-huur-berekend-voor-verschillende-contracttypes',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/6674927-comment-calcule-t-on-le-loyer-pour-differents-types-de-contrat',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/6674927-come-viene-calcolato-l-affitto-per-i-diversi-tipi-di-contratto',
  [Language.pl]:
    'https://answers.housinganywhere.com/pl/articles/6674927-jak-oblicza-sie-wysokosc-czynszu-dla-roznych-rodzajow-umowy',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/articles/6674927-como-o-aluguel-e-calculado-para-diferentes-tipos-de-contrato',
  [Language.ro]:
    'https://answers.housinganywhere.com/ro/articles/6674927-cum-se-calculeaza-chiria-pentru-diferitele-tipuri-de-contract',
  [Language.ru]:
    'https://answers.housinganywhere.com/ru/articles/6674927-как-рассчитывается-арендная-плата-для-разных-типов-договоров',
  [Language.zh]:
    'https://answers.housinganywhere.com/zh-CN/articles/6674927-如何为不同的合同类型计算租金',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/6674927-como-se-calcula-el-alquiler-para-diferentes-tipos-de-contratos',
  [Language.sv]:
    'https://answers.housinganywhere.com/sv/articles/6674927-hur-beraknas-hyran-for-olika-kontraktstyper',
};

export const CancellationPolicyHelpUrls = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/2185219-stornierungsbedingungen-fur-mieter',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/2185219-cancellation-policy-for-tenants',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/2185219-politica-de-cancelacion-para-inquilinos',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/2185219-politique-d-annulation-pour-les-locataires',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/2185219-termini-di-cancellazione-per-inquilini',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/2185219-annuleringsvoorwaarden-voor-huurders',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/articles/2185219-politica-de-cancelamento-para-inquilinos',
};

export const AdvancedPricingSpecialOfferArticle = {
  [Language.de]: 'https://answers.housinganywhere.com/de/articles/4967880',
  [Language.en]: 'https://answers.housinganywhere.com/en/articles/4967880',
  [Language.es]: 'https://answers.housinganywhere.com/es/articles/4967880',
  [Language.fr]: 'https://answers.housinganywhere.com/fr/articles/4967880',
  [Language.it]: 'https://answers.housinganywhere.com/it/articles/4967880',
  [Language.nl]: 'https://answers.housinganywhere.com/nl/articles/4967880',
};

const ExcellentAdverstiserBadgeExplanationLink = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/2685207-was-ist-das-excellent-advertiser-badge',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/2685207-what-is-an-excellent-advertiser-badge',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/2685207-que-es-la-insignia-de-anunciantes-de-excelencia',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/2685207-quel-est-le-badge-excellent-annonceur',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/2685207-che-cos-e-l-excellent-advertiser-badge',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/2685207-wat-betekent-de-badge-uitstekende-verhuurder',
};

export const AdvancedPricingBannerLink = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/collections/1445961#advanced-pricing',
  [Language.en]:
    'https://answers.housinganywhere.com/en/collections/1445961#advanced-pricing',
  [Language.es]:
    'https://answers.housinganywhere.com/es/collections/1445961#advanced-pricing',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/collections/1445961#advanced-pricing',
  [Language.it]:
    'https://answers.housinganywhere.com/it/collections/1445961#advanced-pricing',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/collections/1445961#advanced-pricing',
};

export const AdvancedPricingDescriptionLink = {
  [Language.de]: 'https://answers.housinganywhere.com/de/articles/4967879',
  [Language.en]: 'https://answers.housinganywhere.com/en/articles/4967879',
  [Language.es]: 'https://answers.housinganywhere.com/es/articles/4967879',
  [Language.fr]: 'https://answers.housinganywhere.com/fr/articles/4967879',
  [Language.it]: 'https://answers.housinganywhere.com/it/articles/4967879',
  [Language.nl]: 'https://answers.housinganywhere.com/nl/articles/4967879',
};

export const StructuredCostsHelpUrls = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/4591900-was-sind-kosten-fur-zusatzleistungen-und-wie-veroffentliche-ich-sie',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/4591900-what-are-costs-for-extra-services-and-how-do-i-publish-them',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/4591900-que-son-los-costes-por-servicios-extra-y-como-los-publico',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/4591900-que-sont-les-frais-supplementaires-et-comment-les-ajouter',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/4591900-cosa-sono-i-costi-per-servizi-aggiuntivi-e-come-li-specifico',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/4591900-wat-zijn-kosten-voor-extra-diensten-en-hoe-publiceer-ik-die',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/articles/4591900-o-que-sao-custos-de-servicos-extra-e-como-faco-para-publica-los',
};

export const FlexibleCancellationHelpUrls = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/4680352-wie-funktioniert-die-flexible-stornierungsrichtlinie-fur-inserenten',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/4680352-how-does-the-flexible-cancellation-policy-work-for-advertisers',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/4680352-como-funciona-la-politica-de-cancelacion-flexible-para-los-anunciantes',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/4680352-qu-est-ce-que-l-option-d-annulation-flexible-pour-les-annonceurs',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/4680352-cosa-comporta-la-cancellazione-flessibile',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/4680352-hoe-werken-de-flexibele-annuleringsvoorwaarden-voor-verhuurders',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/articles/4680352-como-funciona-a-politica-de-cancelamento-flexivel-para-os-anunciantes',
};

export const PaymentCalculationArticleUrls = {
  [Language.de]: 'https://answers.housinganywhere.com/de/articles/4967879',
  [Language.en]: 'https://answers.housinganywhere.com/en/articles/4967879',
  [Language.es]: 'https://answers.housinganywhere.com/es/articles/4967879',
  [Language.fr]: 'https://answers.housinganywhere.com/fr/articles/4967879',
  [Language.it]: 'https://answers.housinganywhere.com/it/articles/4967879',
  [Language.nl]: 'https://answers.housinganywhere.com/nl/articles/4967879',
};

export const SubscriptionFAQUrls = {
  [Language.de]: 'https://answers.housinganywhere.com/de/articles/5166556',
  [Language.en]: 'https://answers.housinganywhere.com/en/articles/5166556',
  [Language.es]: 'https://answers.housinganywhere.com/es/articles/5166556',
  [Language.fr]: 'https://answers.housinganywhere.com/fr/articles/5166556',
  [Language.it]: 'https://answers.housinganywhere.com/it/articles/5166556',
  [Language.nl]: 'https://answers.housinganywhere.com/nl/articles/5166556',
  [Language.pt]: 'https://answers.housinganywhere.com/pt/articles/5166556',
};

export const SupportedCurrenciesUrls = {
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/2685216-i-received-a-different-amount-of-money-than-i-expected-why-is-this',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/2685216-i-received-a-different-amount-of-money-than-i-expected-why-is-this',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/2685216-i-received-a-different-amount-of-money-than-i-expected-why-is-this',
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/2685216-i-received-a-different-amount-of-money-than-i-expected-why-is-this',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/2685216-i-received-a-different-amount-of-money-than-i-expected-why-is-this',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/articles/2685216-i-received-a-different-amount-of-money-than-i-expected-why-is-this',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/2685216-i-received-a-different-amount-of-money-than-i-expected-why-is-this',
};

export const VATInformationUrls = {
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/2499317-will-i-be-charged-vat',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/2499317-will-i-be-charged-vat',
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/2499317-will-i-be-charged-vat',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/2499317-will-i-be-charged-vat',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/articles/2499317-will-i-be-charged-vat',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/2499317-will-i-be-charged-vat',
};

export const BookingModificationAndCancellation = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/collections/1445966-booking-modifications-cancellations-and-refunds',
  [Language.en]:
    'https://answers.housinganywhere.com/en/collections/1445966-booking-modifications-cancellations-and-refunds',
  [Language.es]:
    'https://answers.housinganywhere.com/es/collections/1445966-booking-modifications-cancellations-and-refunds',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/collections/1445966-booking-modifications-cancellations-and-refunds',
  [Language.it]:
    'https://answers.housinganywhere.com/it/collections/1445966-booking-modifications-cancellations-and-refunds',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/collections/1445966-booking-modifications-cancellations-and-refunds',
};

export const TenantServiceFeeArticle = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/3161512-what-is-a-payment-request-and-how-can-i-pay-it',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/3161512-what-is-a-payment-request-and-how-can-i-pay-it',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/3161512-what-is-a-payment-request-and-how-can-i-pay-it',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/3161512-what-is-a-payment-request-and-how-can-i-pay-it',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/3161512-what-is-a-payment-request-and-how-can-i-pay-it',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/3161512-what-is-a-payment-request-and-how-can-i-pay-it',
};

export const SupportedCountriesArticle = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/5249847-wo-ist-housinganywhere-verfugbar',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/5249847-where-is-housinganywhere-available',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/5249847-en-que-lugares-esta-housinganywhere-presente',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/5249847-dans-quels-pays-housinganywhere-est-il-disponible',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/5249847-dove-e-disponibile-housinganywhere',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/5249847-waar-is-housinganywhere-beschikbaar',
  [Language.pl]:
    'https://answers.housinganywhere.com/pl/articles/5249847-gdzie-uslugi-housinganywhere-sa-dostepne',
  [Language.sv]:
    'https://answers.housinganywhere.com/sv/articles/5249847-var-ar-housinganywhere-tillgangligt',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/articles/5249847-onde-e-que-a-housinganywhere-esta-disponivel',
  [Language.ro]:
    'https://answers.housinganywhere.com/ro/articles/5249847-unde-este-disponibil-housinganywhere',
  [Language.ru]:
    'https://answers.housinganywhere.com/ru/articles/5249847-где-доступна-платформа-housinganywhere',
};

export const AdvertiserServiceFeeArticle = {
  [Language.de]: '/zahlungsaufforderungen-richtig-senden',
  [Language.en]: '/how-to-send-payment-requests',
  [Language.es]: '/como-enviar-solicitudes-de-pago',
  [Language.fr]: '/comment-envoyer-demandes-de-paiement',
  [Language.it]: '/come-inviare-richieste-di-pagamento',
  [Language.nl]: '/hoe-betaalverzoek-verzenden',
};

export const DAC7FAQArticle = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/articles/8587304-dac7-reporting-at-housinganywhere',
  [Language.en]:
    'https://answers.housinganywhere.com/en/articles/8587304-dac7-reporting-at-housinganywhere',
  [Language.es]:
    'https://answers.housinganywhere.com/es/articles/8587304-dac7-reporting-at-housinganywhere',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/articles/8587304-dac7-reporting-at-housinganywhere',
  [Language.it]:
    'https://answers.housinganywhere.com/it/articles/8587304-dac7-reporting-at-housinganywhere',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/articles/8587304-dac7-reporting-at-housinganywhere',
};

const TenantFAQURLs = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/collections/1445947-mieter',
  [Language.en]:
    'https://answers.housinganywhere.com/en/collections/1445947-tenants',
  [Language.es]:
    'https://answers.housinganywhere.com/es/collections/1445947-inquilinos',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/collections/1445947-locataires',
  [Language.it]:
    'https://answers.housinganywhere.com/it/collections/1445947-inquilini',
  [Language.nl]:
    'https://answers.housinganywhere.com/nl/collections/1445947-huurders',
  [Language.pl]:
    'https://answers.housinganywhere.com/pl/collections/1445947-najemcy',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/collections/1445947-inquilinos',
  [Language.ro]:
    'https://answers.housinganywhere.com/ro/collections/1445947-chiriași',
  [Language.ru]:
    'https://answers.housinganywhere.com/ru/collections/1445947-арендаторы',
  [Language.sv]:
    'https://answers.housinganywhere.com/sv/collections/1445947-hyresgaster',
};

const AdvertiserFAQURLs = {
  [Language.de]:
    'https://answers.housinganywhere.com/de/collections/1445961-vermieter',
  [Language.en]:
    'https://answers.housinganywhere.com/en/collections/1445961-advertisers',
  [Language.es]:
    'https://answers.housinganywhere.com/es/collections/1445961-anunciantes',
  [Language.fr]:
    'https://answers.housinganywhere.com/fr/collections/1445961-proprietaires',
  [Language.it]:
    'https://answers.housinganywhere.com/it/collections/1445961-inserzionisti',
  [Language.nl]:
    'https://answers.housinganywhere.com/en/collections/1445961-advertisers',
  [Language.pl]:
    'https://answers.housinganywhere.com/pl/collections/1445961-reklamodawcy',
  [Language.pt]:
    'https://answers.housinganywhere.com/pt/collections/1445961-anunciantes',
  [Language.ro]:
    'https://answers.housinganywhere.com/ro/collections/1445961-agent-de-publicitate',
  [Language.ru]:
    'https://answers.housinganywhere.com/ru/collections/1445961-рекламодатели',
  [Language.sv]:
    'https://answers.housinganywhere.com/sv/collections/1445961-annonsorer',
};

// keep shared values in Referrer in sync with https://github.com/housinganywhere/ha/blob/main/apps/rms/src/core/Routing/index.ts
export enum Referrer {
  AvailabilityOverview = 'calendar',
}

export enum TrustPilotReferrer {
  MoveInSurveyReview = 'Survey',
  TalkPage = 'Talkpage',
  Email = 'Email',
}

export enum AuthPageTarget {
  PrivateLandlord = 'private',
  Tenant = 'tenant',
}

export enum AuthPageQueryActions {
  DEFAULT = 'default',
  CREATE_SEARCH_ALERT = 'alerts',
  ADD_FAVORITE_LISTING = 'favorite',
  CONTACT_ADVERTISER = 'contact',
  MESSAGE_ADVERTISER = 'message',
  REQUEST_TO_BOOK = 'requestToBook',
  UNIVERSITY_SIGNUP = 'university',
}

export type AuthPageQueryParamsBase = {
  target?: AuthPageTarget;
  message?: string;
  prevUrl?: string;
};

export type AuthPageQueryParamsTenant = AuthPageQueryParamsBase & {
  target: AuthPageTarget.Tenant;
  action?: AuthPageQueryActions;
  university?: string;
};

export type AuthPageQueryParams =
  | AuthPageQueryParamsBase
  | AuthPageQueryParamsTenant;

const myMatchPattern = new UrlPattern('/my(/*)');
const langMyMatchPattern = new UrlPattern('/:lang/my(/*)');

interface Config {
  /** @todo(nader) rename to `origin` */
  baseDomain: string;
  /** @todo(nader) rename to `rmsOrigin` */
  rmsBaseDomain?: string;
  lang: Language;
}

export class UrlResolver {
  private baseDomain: string;

  private rmsBaseDomain: string;

  private lang: Language;

  static create = (config: Config): UrlResolver => new UrlResolver(config);

  constructor({ baseDomain, lang, rmsBaseDomain }: Config) {
    this.baseDomain = baseDomain;
    this.lang = lang;
    this.rmsBaseDomain = rmsBaseDomain ?? baseDomain;
  }

  applyFilterToSearchUrl = (pathname: string, slug?: string): string =>
    `${pathname}${slug ? `/${slug}` : ''}`;

  getBaseUrl() {
    return this.baseDomain;
  }

  getPathWithoutLang(pathname: string) {
    return pathname.replace(new RegExp(`^/(${this.lang})(/|$)`), '/');
  }

  absolutizePath(
    path: string,
    {
      skipAddLanguage = false,
      lang,
    }: { skipAddLanguage?: boolean; lang?: Language } = {},
  ) {
    if (isAbsoluteUrl(path)) {
      return path;
    }

    return skipAddLanguage
      ? `${this.getBaseUrl()}${path}`
      : `${this.getBaseUrl()}${this.formatHrefURL(path, lang)}`;
  }

  formatHrefURL(path: string, lang?: Language) {
    return formatHrefURL(path, lang || this.lang);
  }

  getAnswersUrl = (): string => answersLangToPath[this.lang];

  getBrowserNotSupportedUrl = (): string =>
    this.formatHrefURL('/browser-not-supported');

  getCareersUrl = (): string => this.formatHrefURL('/careers', Language.en);

  getGreenHouseCareersUrl = (): string =>
    'https://boards.greenhouse.io/housinganywhere/';

  getLandlordsBlogUrl = (lang: Language, topic?: TopicCluster): string => {
    const path = `/${landlordBlogPath[lang]}`;

    if (!topic) {
      return this.formatHrefURL(path, lang);
    }

    return this.formatHrefURL(`${path}#${topic}`, lang);
  };

  hasLandlordsBlogUrl = (lang: Language): boolean =>
    Boolean(landlordBlogPath[lang]);

  getTenantsGlobalBlogUrl = (): string =>
    this.formatHrefURL(getTenantsGlobalBlogPath());

  getTenantsCountryBlogUrl = (countrySlug: string): string =>
    this.formatHrefURL(getTenantsGlobalBlogPath() + countrySlug);

  getTenantsCityBlogUrl = (cityCountrySlug: string): string =>
    this.formatHrefURL(getTenantsGlobalBlogPath() + cityCountrySlug);

  getHelpCenterUrl = (lang: Language): string =>
    this.formatHrefURL(PREV_TO_NEXT_HELP_CENTER_PATHS[lang]?.next, lang);

  hasHelpCenterUrl = (lang: Language): boolean =>
    Boolean(PREV_TO_NEXT_HELP_CENTER_PATHS[lang]?.next);

  getDashboardUrl() {
    return this.formatHrefURL('/my');
  }

  getShareRoomUrl = (
    roomId: number,
    { verified }: { verified?: boolean } = {},
  ): string =>
    this.formatHrefURL(
      `/my/share-room/${roomId}${buildParams({
        verified: verified ? 1 : null,
      })}`,
    );

  getListingSuccessUrl = (
    roomId: number | string,
    query?: {
      [key: string]: string | number | boolean;
    },
  ): string =>
    this.formatHrefURL(
      `/my/listings/${roomId}/success${query ? buildParams(query) : ''}`,
    );

  getCalendarSyncGuideUrl = (): string =>
    calendarSyncGuidePathByLang[this.lang]
      ? this.absolutizePath(calendarSyncGuidePathByLang[this.lang])
      : this.absolutizePath(calendarSyncGuidePathByLang.en, {
          skipAddLanguage: true,
        });

  getFbAuthUrl = (searchParams: Record<string, unknown>): string =>
    `https://www.facebook.com/dialog/oauth${buildParams(searchParams)}`;

  getGoogleAuthUrl = (searchParams: Record<string, unknown>): string =>
    `https://accounts.google.com/o/oauth2/auth${buildParams(searchParams)}`;

  getFacebookShareUrl = (
    url: string,
    params: Record<string, unknown>,
  ): string =>
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url,
    )}${buildParams(params)}`;

  getBecomeAnAdvertiserUrl() {
    return this.formatHrefURL(
      becomeAnAdvertiserLangToPath[this.lang] ||
        becomeAnAdvertiserLangToPath.en,
    );
  }

  getRecoverAccountUrl() {
    return this.formatHrefURL('/recover-account');
  }

  getRedirectUrl = (url: string | null): string => {
    const dashboardUrl = `${this.getBaseUrl()}${this.getDashboardUrl()}`;

    return !url || isAbsoluteUrl(url) || url.startsWith('//')
      ? dashboardUrl
      : url;
  };

  getSearchUrl = (
    city: string,
    country: string,
    filters?: Record<string, unknown>,
    category?: string,
  ): string => {
    const path = [
      '/s/',
      encodeSearchQuery([city, country].join(', ')),
      category ? `/${category}` : '',
      filters ? buildSearchQuery(filters) : '',
    ];

    return this.formatHrefURL(path.join(''));
  };

  getCityUrl = (countrySlug: string, citySlug: string): string =>
    this.formatHrefURL(getCityCountryUrl(citySlug, countrySlug));

  getGuideUrl = (
    slug: string,
    countrySlug: string,
    citySlug?: string,
  ): string => {
    const url = citySlug
      ? `${getCityCountryUrl(citySlug, countrySlug)}/${slug}`
      : `/${capitalizeFirst(countrySlug)}/${slug}`;

    return this.formatHrefURL(url);
  };

  getCountryUrl = (countrySlug: string, category?: string): string => {
    const url = category
      ? `/${capitalizeFirst(countrySlug)}/${category}`
      : `/${capitalizeFirst(countrySlug)}`;

    return this.formatHrefURL(url);
  };

  getCountryGuideUrl = (countrySlug: string): string =>
    this.formatHrefURL(`/${capitalizeFirst(countrySlug)}`);

  getContactUrl = (lang?: Language): string =>
    this.formatHrefURL('/contact', lang);

  getUniversityUrl = (
    countrySlug: string,
    citySlug: string,
    universitySlug: string,
  ): string =>
    this.formatHrefURL(
      `${getCityCountryUrl(
        citySlug,
        countrySlug,
      )}/${universitySlug.toLowerCase()}`,
    );

  getPartnershipsUrl = (): string =>
    'https://info.housinganywhere.com/partnerships';

  getMySurveyUrl = (surveyUUID: string): string =>
    this.formatHrefURL(`/my/surveys/${surveyUUID}`);

  getMyListingsUrl = (): string => this.formatHrefURL('/my/listings');

  getMyListingsSettingsUrl = (): string =>
    this.formatHrefURL('/my/listings/settings');

  getMyInboxUrl = ({
    state,
    unitTypeId,
    text,
    page,
  }: {
    state?: keyof typeof ConversationsFilters;
    listingId?: string | number;
    unitTypeId?: string | number;
    text?: string;
    page?: number;
  } = {}): string =>
    this.formatHrefURL(
      `/my/inbox${buildParams({
        state: (state && ConversationsFilters[state]) || undefined,
        unitTypeId,
        text,
        page,
      })}`,
    );

  getEditListingUrl = (
    listingId: number | string,
    query?: {
      isDuplicate?: boolean;
      [MUL_QUERY_KEY]?: boolean;
    },
  ): string =>
    this.formatHrefURL(
      `/my/listings/${listingId}/edit${query ? buildParams(query) : ''}`,
    );

  getEditDraftListingUrl = (
    listingId: number | string,
    query?: {
      [key: string]: string | number | boolean;
    },
  ): string =>
    this.formatHrefURL(
      `/my/listings/${listingId}/edit-draft${query ? buildParams(query) : ''}`,
    );

  getListingAvailabilityStatusUrl = (props: {
    listingId: number | string;
    status: AvailabilityStatusType;
    query?: Record<string, unknown>;
  }): string =>
    this.formatHrefURL(
      `/my/listings/${props.listingId}/availability-status/${
        props.status
      }${buildParams(props.query || {})}`,
    );

  getGetVerifiedUrl = (listingId: number | string): string =>
    this.formatHrefURL(`/my/get-verified${buildParams({ roomId: listingId })}`);

  getVerifyPhoneNumberUrl = (query: {
    listingId: number | string;
    [MUL_QUERY_KEY]?: boolean;
  }): string =>
    this.formatHrefURL(`/my/verify-phone-number${buildParams(query)}`);

  getTermsUrl = (): string => this.formatHrefURL('/terms');

  getLinkToUserPage = (userId: number | string): string =>
    this.formatHrefURL(`/users/show/${userId}`);

  getLinkToEditUserPage = (section?: ProfileSectionsValues): string =>
    this.formatHrefURL(`/my/profile/edit${buildParams({ section })}`);

  getPressUrl = (): string => this.formatHrefURL('/press-releases');

  getLegalUrl = (lang?: Language): string => this.formatHrefURL('/legal', lang);

  getCookiePolicyUrl = (): string => this.formatHrefURL('/cookie-policy');

  getCookiePolicyTablesUrl = (): string =>
    this.formatHrefURL('/cookie-policy/tables');

  getPrivacyPolicyUrl = (): string => this.formatHrefURL('/privacy-policy');

  getSitemapUrl = (lang?: Language): string =>
    this.formatHrefURL('/sitemap', lang);

  getAboutUrl = (): string => this.formatHrefURL('/about');

  getMyTenantReviewsDashboard = (): string =>
    this.formatHrefURL(`/my/tenant-reviews`);

  getMyTenantReviewsOverview = (tenancyUUID?: string): string =>
    this.formatHrefURL(`/my/tenant-reviews/${tenancyUUID}`);

  getListingCalendarUrl = (
    id: number,
    query?: {
      [key: string]: string | number | boolean;
    },
  ): string =>
    this.formatHrefURL(
      `/my/listings/${id}/calendar${query ? buildParams(query) : ''}`,
    );

  getListYourPlaceUrl = (query?: {
    [key: string]: string | number | boolean;
  }): string =>
    this.formatHrefURL(`/list-your-place${query ? buildParams(query) : ''}`);

  getPaymentRequestHandleUrl = (handle: string): string =>
    this.absolutizePath(`/my/pay/${handle}`);

  getPaymentRequestHandleRelativeUrl = (handle: string): string =>
    this.formatHrefURL(`/my/pay/${handle}`);

  getPaymentURL = (handle: string, payMode: PayMode) =>
    this.formatHrefURL(`/my/payment/${handle}/${payMode}`);

  getPaymentRequestTrackingUrl = (handle: string): string =>
    this.formatHrefURL(`/my/pay/${handle}/track`);

  getRentGuaranteeChooseGuarantorUrl = ({
    conversationId,
  }: {
    conversationId: number | string;
  }): string =>
    this.formatHrefURL(`/my/rent-guarantee/${conversationId}/choose-guarantor`);

  getRentGuaranteeUploadDocumentsUrl = ({
    conversationId,
    guarantorType,
  }: {
    conversationId: number | string;
    guarantorType: GuarantorType;
  }): string =>
    this.formatHrefURL(
      `/my/rent-guarantee/${conversationId}/${guarantorType}/upload-documents`,
    );

  getConversationUrl = (
    conversationId: number | string,
    from?: string,
  ): string =>
    this.formatHrefURL(`/my/talk/${conversationId}${buildParams({ from })}`);

  getConversationBookingUrl = (conversationId: number | string): string =>
    this.formatHrefURL(`/my/talk/${conversationId}/book-request`);

  getPaymentUrl = (conversationId: number | string): string =>
    this.formatHrefURL(`/my/talk/${conversationId}/book`);

  getPayURL = (id: number, mode: PayMode) =>
    this.formatHrefURL(`/my/talk/${id}/${mode}`);

  getPayConfirmURL = (id: number) =>
    this.formatHrefURL(`/my/talk/${id}/confirm`);

  getBillingUrl = (): string => this.formatHrefURL('/my/payouts/billing');

  getPaymentBaseUrl = (): string => this.formatHrefURL('/my/payments');

  getPaymentMethodsUrl = (): string =>
    this.formatHrefURL('/my/payments/methods');

  getPaymentsPaymentRequestsUrl = (): string =>
    this.formatHrefURL('/my/payments/requests');

  getPayoutBaseUrl = (): string => this.formatHrefURL('/my/payouts');

  getPayoutMethodsUrl = (): string =>
    this.formatHrefURL('/my/payouts/preferences');

  getPayoutRoutingUrl = (payentMethodId: string): string =>
    this.formatHrefURL(`/my/payouts/preferences/${payentMethodId}/routing`);

  getInvoicesUrl = (): string => this.formatHrefURL('/my/payouts/invoices');

  getTransactionHistoryUrl = (): string =>
    this.formatHrefURL('/my/payouts/history');

  getPayoutsPaymentRequests = (tenancyUUID?: string): string =>
    this.formatHrefURL(
      `/my/payouts/payment-requests${
        tenancyUUID ? `?tenancy=${tenancyUUID}` : ''
      }`,
    );

  getPaymentPlan = (conversationId?: number | string): string =>
    this.formatHrefURL(
      `/my/payment-plan${conversationId ? `/${conversationId}` : ''}`,
    );

  getAcceptPaymentPlan = (
    inviteToken: string,
    tenantPreferredLanguage?: string,
  ): string =>
    this.formatHrefURL(
      `${
        tenantPreferredLanguage && tenantPreferredLanguage !== 'en'
          ? `/${tenantPreferredLanguage}`
          : ''
      }/payment-plan/${inviteToken}/accept`,
    );

  getPaymentPlanLanding = (
    inviteToken: string,
    tenantPreferredLanguage?: string,
  ): string =>
    this.formatHrefURL(
      `/payment-plan/${inviteToken}`,
      tenantPreferredLanguage as Language,
    );

  getCommissionPlanUrl = (): string => this.formatHrefURL('/my/payouts/plan');

  getVerifyIdentityUrl = (): string =>
    this.formatHrefURL('/my/payouts/verify-identity');

  getFacebookUrl = (): string => socialUrls.facebookUrl;

  getTwitterUrl = (): string => socialUrls.twitterUrl;

  getLinkedinUrl = (): string => socialUrls.linkedinUrl;

  getYoutubeUrl = (): string => socialUrls.youtubeUrl;

  getInstagramUrl = (): string => socialUrls.instagram;

  getBookingsUrl = (): string => this.formatHrefURL('/my/bookings');

  getUserProfileUrl = (userId: number | string): string =>
    `/my/profile/${userId}`;

  getAdminPagesUrl = (): string => '/admin';

  getFavoritesUrl = (): string => this.formatHrefURL('/my/favorites');

  getSearchAlertsUrl = (): string => this.formatHrefURL('/my/searches');

  getHomeUrl = ({
    explicitNoRedirect,
  }: {
    /** Explicitly prevent the page from redirecting to RMS dashboard - only applicable for landlords. For other users use `false` */
    explicitNoRedirect?: boolean;
  } = {}): string => {
    return this.formatHrefURL(`/${explicitNoRedirect ? '?noredirect=1' : ''}`);
  };

  getMyUniversityUrl = (): string => {
    return this.formatHrefURL('/my/university');
  };

  getRenting = (): string => this.formatHrefURL(renting[this.lang]);

  getRentingOut = (): string => this.formatHrefURL(rentingOut[this.lang]);

  getPaymentsTenant = (): string =>
    this.formatHrefURL(tenantPayments[this.lang]);

  getPaymentsLandlord = (): string =>
    this.formatHrefURL(landlordPayments[this.lang]);

  getPricingTenantsUrl = (lang?: Language): string =>
    this.formatHrefURL(PricingUrls.tenant[lang || this.lang], lang);

  getPricingLandlordsUrl = (lang?: Language): string =>
    this.formatHrefURL(PricingUrls.landlord[lang || this.lang], lang);

  getRentRadarUrl = (): string =>
    `https://rentradar.housinganywhere.com/${
      rentRadarLanguangesWithLeadFormSupported.includes(this.lang)
        ? 'lead/'
        : ''
    }${rentRadarLanguanges.includes(this.lang) ? this.lang : ''}`;

  getKrugerPRUrl = (): string =>
    krugerLangToPath[this.lang] || krugerLangToPath.en;

  getRenmateFAQUrl = (): string =>
    'https://answers.housinganywhere.com/en/articles/3732114-how-will-the-rentmate-acquisition-affect-me';

  getStanzaZooArticleUrl = (): string =>
    this.formatHrefURL(
      '/housinganywhere_lannuncia_acquisizione_di_stanzazoo',
      Language.it,
    );

  getCoronaFAQUrl = (): string =>
    'https://answers.housinganywhere.com/en/articles/3746319-coronavirus-cancellation-policy';

  getCancellationPolicyHelpUrl = (): string =>
    CancellationPolicyHelpUrls[this.lang] || CancellationPolicyHelpUrls.en;

  getStructuredCostsHelpUrl = (): string =>
    StructuredCostsHelpUrls[this.lang] || StructuredCostsHelpUrls.en;

  getFlexibleCancellationHelpUrl = (): string =>
    FlexibleCancellationHelpUrls[this.lang] || FlexibleCancellationHelpUrls.en;

  getPaymentCalculationArticleUrl = (): string =>
    PaymentCalculationArticleUrls[this.lang] ||
    PaymentCalculationArticleUrls.en;

  getAdvancedPricingSpecialOfferArticle = (): string =>
    AdvancedPricingSpecialOfferArticle[this.lang] ||
    AdvancedPricingSpecialOfferArticle.en;

  getExcellentAdverstiserBadgeExplanationLink = (): string =>
    ExcellentAdverstiserBadgeExplanationLink[this.lang] ||
    ExcellentAdverstiserBadgeExplanationLink.en;

  getAdvancedPricingBannerLink = (): string =>
    AdvancedPricingBannerLink[this.lang] || AdvancedPricingBannerLink.en;

  getAdvancedPricingDescriptionLink = (): string =>
    AdvancedPricingDescriptionLink[this.lang] ||
    AdvancedPricingDescriptionLink.en;

  getSupportedCountriesArticle = (): string =>
    SupportedCountriesArticle[this.lang] || SupportedCountriesArticle.en;

  getAdvertiserTenantReviewsUrl = (advertiserId: number): string =>
    this.formatHrefURL(`/users/show/${advertiserId}#advertiser-review-reports`);

  getContactAdvertiserUnitTypeUrl = (
    unitTypeId: number,
    params: {
      startDate?: string | null;
      endDate?: string | null;
      srp?: number;
      requestToBook?: boolean;
    },
    currentStep?: BookingFlowSteps,
  ): string =>
    this.formatHrefURL(
      `/my/book/contact/ut${unitTypeId}${
        currentStep ? `/${currentStep}` : ''
      }${buildParams(params)}`,
    );

  getSecurePaymentUrl = (): string => {
    if (securePayments[this.lang]) {
      return this.formatHrefURL(securePayments[this.lang], this.lang);
    }

    return this.formatHrefURL(securePayments[Language.en], Language.en);
  };

  /**
   * Checks if current url is one of "/my" pages, excluding booking flow
   */
  isOnAccountPages = (pathname: string): boolean =>
    !pathname.includes('my/book/contact') &&
    !pathname.match(/my\/talk\/\d+\/book-request/) &&
    (Boolean(myMatchPattern.match(pathname)) ||
      Boolean(langMyMatchPattern.match(pathname)));

  getHomePageDataUrl = (lang: Language): string =>
    `/${lang === Language.en ? '' : `${lang}/`}home`;

  getAbsBookingLinkUrl = (listingId: number): string =>
    this.absolutizePath(`/link/${base64.btoa(String(listingId))}`);

  getSubscriptionActivateUrl = (
    handle: SubscriptionUserType,
    query: BillingSubscriptionActivateQuery,
  ) =>
    this.formatHrefURL(
      `/subscription/${handle}/activate${buildParams(
        query as Record<string, unknown>,
      )}`,
    );

  getSubscriptionLandingUrl = (
    handle: SubscriptionUserType,
    returnUrl?: string,
  ) =>
    this.formatHrefURL(
      `/subscription/${handle}${buildParams({
        return_url: returnUrl,
      })}`,
    );

  getSubscriptionFAQUrl = () =>
    SubscriptionFAQUrls[this.lang] || SubscriptionFAQUrls.en;

  getSupportedCountriesURL = (): string =>
    SupportedCountriesUrls[this.lang] || SupportedCountriesUrls.en;

  getKamernetCitySearchURL = (city: string): string => {
    const queryParams = '?utm-source=HA&utm-campaign=NL-focus';

    switch (this.lang) {
      case Language.nl:
        return `https://kamernet.nl/huren/kamers-${city}${queryParams}`;
      default:
        return `https://kamernet.nl/en/for-rent/rooms-${city}${queryParams}`;
    }
  };

  getSupportedCurrenciesUrl = (): string =>
    SupportedCurrenciesUrls[this.lang] || SupportedCurrenciesUrls.en;

  getVerifyMyIdentityAnswerUrl = () =>
    this.getAnswersArticleUrl(
      4818082,
      'why-is-housinganywhere-verifying-my-identity',
    );

  getVATInformationUrl = (): string =>
    VATInformationUrls[this.lang] || VATInformationUrls.en;

  // Routes for the refregee flow
  getQuickRepliesSettingsUrl = (): string =>
    this.formatHrefURL(`/my/inbox/quick-replies-settings`);

  getAutomationSettingsUrl = (): string =>
    this.formatHrefURL(`/my/inbox/automation-settings`);

  // Routes for the careers flow
  getHowWeHireUrl = (): string => this.formatHrefURL('/careers/how-we-hire');

  getCultureUrl = (): string => this.formatHrefURL('/careers/culture');

  getAllRolesUrl = (): string => this.formatHrefURL('/careers/all-roles');

  getInternshipsUrl = (): string => this.formatHrefURL('/careers/internships');

  getCareersEngineering = (): string =>
    this.formatHrefURL('/careers/engineering');

  getCareersProductDesign = (): string =>
    this.formatHrefURL('/careers/product-design');

  getCareersDataAnalytics = (): string =>
    this.formatHrefURL('/careers/data-analytics');

  getCareersMarketing = (): string => this.formatHrefURL('/careers/marketing');

  getCareersSales = (): string => this.formatHrefURL('/careers/sales');

  getCareersSupport = (): string => this.formatHrefURL('/careers/support');

  getCareersFinance = (): string => this.formatHrefURL('/careers/finance');

  getCareersPeopleOps = (): string => this.formatHrefURL('/careers/people-ops');

  getAnswersArticleUrl = (articleId: number, slug?: string) => {
    const lang =
      this.lang === Language.zh || this.lang === Language.uk ? 'en' : this.lang;

    return `https://answers.housinganywhere.com/${lang}/articles/${articleId}${
      slug ? `-${slug}` : ''
    }`;
  };

  getContractTypesArticleUrl = () => {
    return (
      ContractTypeExplanationArticle[this.lang] ||
      ContractTypeExplanationArticle.en
    );
  };

  getExcellentAdvertiserBadgeArticleUrl = () => {
    return this.getAnswersArticleUrl(2685207);
  };

  getIdenticalUnitsArticleUrl = () => {
    return this.getAnswersArticleUrl(7222910, 'what-are-identical-units');
  };

  getLandlordsRentGuaranteeArticleUrl = () => {
    return this.getAnswersArticleUrl(
      10278959,
      'housinganywhere-rent-guarantee-for-landlords',
    );
  };

  getTenantsRentGuaranteeArticleUrl = () => {
    return this.getAnswersArticleUrl(
      10278788,
      'housinganywhere-rent-guarantee-for-landlords',
    );
  };

  getOAuthSigninUrl = (
    /** passing `undefined` makes operation navigate back to referrer */
    continueURL?: string,
    queryParams?: AuthPageQueryParams,
  ) => {
    const searchParams = new URLSearchParams(queryParams);

    if (continueURL) {
      searchParams.set(
        'continue_url',
        this.absolutizePath(continueURL, {
          skipAddLanguage: true,
        }),
      );
    }

    const stringSearchParams = searchParams.toString();

    return `${AUTH_SIGNIN_URL}${
      stringSearchParams ? `?${stringSearchParams}` : ''
    }`;
  };

  getOAuthSignupUrl = (
    /** passing `undefined` makes operation navigate back to referrer */
    continueURL?: string,
    queryParams?: AuthPageQueryParams,
  ) => {
    const searchParams = new URLSearchParams(queryParams);

    if (continueURL) {
      searchParams.set(
        'continue_url',
        this.absolutizePath(continueURL, {
          skipAddLanguage: true,
        }),
      );
    }

    const stringSearchParams = searchParams.toString();

    return `${AUTH_SIGNUP_URL}${
      stringSearchParams ? `?${stringSearchParams}` : ''
    }`;
  };

  getDirectMessaging = (): string => {
    if (directMessaging[this.lang]) {
      return this.formatHrefURL(directMessaging[this.lang], this.lang);
    }

    return this.formatHrefURL(directMessaging[Language.en], Language.en);
  };

  getPrivateLandlordLandingPageUrl = (): string => {
    return this.formatHrefURL(
      `/${
        PrivateLandlordPageCountrySlugMap[USER_COUNTRY_DEFAULT_VALUE][this.lang]
      }`,
    );
  };

  isSearchCityPage = (url: string): boolean => {
    //  we consider /s/Barcelona--Spain a city search page and
    //  /s/Barcelona--Spain/apartment-for-rent as a search category
    //  we verify if it's a search page first

    return url.includes('/s') && url.split('/').length === 3;
  };

  getRMSDashboardUrl = (): string => {
    return `${this.rmsBaseDomain}/${this.lang}/dashboard`;
  };

  getRMSAvailabilityOverviewUrl = (): string => {
    return `${this.rmsBaseDomain}/${this.lang}/calendar`;
  };

  getAppealingDescriptionArticle = (): string =>
    this.formatHrefURL('/how-to-write-appealing-description');

  getBookingModificationAndCancellationArticle = (): string =>
    BookingModificationAndCancellation[this.lang] ||
    BookingModificationAndCancellation.en;

  getPaymentsAndComplianceUrl = (unitTypeId: number) =>
    this.formatHrefURL(`/my/payment-and-legal/${unitTypeId}`);

  getAdvertiserServiceFeeURL = (): string => {
    if (AdvertiserServiceFeeArticle[this.lang]) {
      return this.formatHrefURL(
        AdvertiserServiceFeeArticle[this.lang],
        this.lang,
      );
    }

    return this.formatHrefURL(AdvertiserServiceFeeArticle.en, Language.en);
  };

  getTenantServiceFeeURL = (): string =>
    TenantServiceFeeArticle[this.lang] || TenantServiceFeeArticle.en;

  getDAC7FAQURL = (): string => DAC7FAQArticle[this.lang] || DAC7FAQArticle.en;

  getTenantFAQURL = (): string => TenantFAQURLs[this.lang] || TenantFAQURLs.en;

  getAdvertiserFAQURL = (): string =>
    AdvertiserFAQURLs[this.lang] || AdvertiserFAQURLs.en;

  getIntegrationsUrl = (): string =>
    this.formatHrefURL(integrations[this.lang] || integrations[Language.en]);

  isOnIntegrationsPage = (pathname: string): boolean =>
    decodeURIComponent(pathname).includes(
      integrations[this.lang] || integrations[Language.en],
    );

  getPartnerDiscountUrl = (): string => this.getAnswersArticleUrl(2499100);

  getSharingSupportingDocumentsArticleUrl = () => {
    return this.getAnswersArticleUrl(9755993, 'sharing-supporting-documents');
  };

  getTenantBookingCancellationUrl = (conversationId: number | string): string =>
    this.formatHrefURL(`/my/talk/${conversationId}/cancel`);
}
